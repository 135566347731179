/* tamil */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9M6VLYz2tZ9rI.woff2)
        format('woff2');
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}
/* latin-ext */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9U6VLYz2tZ9rI.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
        U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9a6VLYz2tZ.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
/* tamil */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9M6VLYz2tZ9rI.woff2)
        format('woff2');
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}
/* latin-ext */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9U6VLYz2tZ9rI.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
        U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9a6VLYz2tZ.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
/* tamil */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9M6VLYz2tZ9rI.woff2)
        format('woff2');
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}
/* latin-ext */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9U6VLYz2tZ9rI.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
        U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9a6VLYz2tZ.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
/* tamil */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9M6VLYz2tZ9rI.woff2)
        format('woff2');
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}
/* latin-ext */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9U6VLYz2tZ9rI.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
        U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Catamaran';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/catamaran/v18/o-0mIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjct6L1SoM-jCpoiyAaBO9a6VLYz2tZ.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKZ-Go6G5tXcraaGwCKd6xBDFs.woff2)
        format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKZ-Go6G5tXcrabGwCKd6xBDFs.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
        U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKZ-Go6G5tXcraVGwCKd6xB.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
/* thai */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr5mOBWzVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr5mOBWoVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr5mOBWpVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
        U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr5mOBWnVaFrNlJz.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
/* thai */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr5KPxWzVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr5KPxWoVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr5KPxWpVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
        U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr5KPxWnVaFrNlJz.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
/* thai */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr4uPhWzVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr4uPhWoVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr4uPhWpVaFrNlJzIu4.woff2)
        format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020,
        U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v13/nKKU-Go6G5tXcr4uPhWnVaFrNlJz.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

* {
    -webkit-tap-highlight-color: transparent;
}

body {
    height: 100%;
    background-color: rgba(26, 27, 39, 1);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 56px white inset !important;
}
